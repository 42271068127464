const pricingTiersSticky = () => {
  const header = document.querySelector("header");
  const pricingKeys = document.querySelector(".pricing-keys");

  const descriptionBtns = document.querySelectorAll(".toggle-key-description");

  if (descriptionBtns.length > 0) {
    for (let index = 0; index < descriptionBtns.length; index++) {
      descriptionBtns[index].addEventListener("click", () => {
        pricingKeys.classList.toggle("minimal");
      });
    }
  }

  let lastScrollTop = 0;

  document.addEventListener("scroll", () => {
    const headerHeight = header.getBoundingClientRect().height;

    const pricingKeysTop = pricingKeys.getBoundingClientRect().top;

    // Window scrollY start position
    const st = window.scrollY;

    if (st > lastScrollTop) {
      if (pricingKeysTop === headerHeight) {
        pricingKeys.classList.add("minimal");
      }
    } else {
      if (pricingKeysTop > headerHeight) {
        pricingKeys.classList.remove("minimal");
      }
    }

    // Update scrollY position
    lastScrollTop = st <= 0 ? 0 : st;
  });
};

pricingTiersSticky();
